export const MOSTRAR_SEGMENTOS = "MOSTRAR_SEGMENTOS";
export const MOSTRAR_PRODUCTOS = "MOSTRAR_PRODUCTOS";
export const MOSTRAR_PRODUCTO = "MOSTRAR_PRODUCTO";
export const MOSTRAR_CATEGORIAS = "MOSTRAR_CATEGORIAS";
export const MOSTRAR_SUBCATEGORIAS = "MOSTRAR_SUBCATEGORIAS";
export const MOSTRAR_BUSQUEDA = "MOSTRAR_BUSQUEDA";
export const MOSTRAR_BUSQUEDAS = "MOSTRAR_BUSQUEDAS";
export const MOSTRAR_CATALOGO = "MOSTRAR_CATALOGO";
export const MOSTRAR_CATALOGOPRODUCTOS = "MOSTRAR_CATALOGOPRODUCTOS";
export const MOSTRAR_RESPUESTA = "MOSTRAR_RESPUESTA";
export const MOSTRAR_BUSQUEDAS_COMPLETO_EXITO =
  "MOSTRAR_BUSQUEDAS_COMPLETO_EXITO";
export const MOSTRAR_BUSQUEDAS_COMPLETO_INICIO =
  "MOSTRAR_BUSQUEDAS_COMPLETO_INICIO";
export const MOSTRAR_BUSQUEDAS_COMPLETO_ERROR =
  "MOSTRAR_BUSQUEDAS_COMPLETO_ERROR";

export const PRODUCTOS_NUEVOS = "PRODUCTOS_NUEVOS";
export const PRODUCTOS_MAS_VENDIDOS = "PRODUCTOS_MAS_VENDIDOS";
export const PRODUCTOS_DESTACADOS = "PRODUCTOS_DESTACADOS";
export const PRODUCTO_OFERTA = "PRODUCTO_OFERTA";

export const PAGINA_BANNER = "PAGINA_BANNER";

export const PAGINA_BANNER_INICIO = "PAGINA_BANNER_INICIO";
export const PAGINA_BANNER_EXITO = "PAGINA_BANNER_EXITO";
export const PAGINA_BANNER_ERROR = "PAGINA_BANNER_ERROR";

export const AGREGAR_PRODUCTO_INICIO = "AGREGAR_PRODUCTO_INICIO";
export const AGREGAR_PRODUCTO_EXITO = "AGREGAR_PRODUCTO_EXITO";
export const AGREGAR_PRODUCTO_ERROR = "AGREGAR_PRODUCTO_ERROR";

export const ELIMINAR_PRODUCTO_INICIO = "ELIMINAR_PRODUCTO_INICIO";
export const ELIMINAR_PRODUCTO_EXITO = "ELIMINAR_PRODUCTO_EXITO";
export const ELIMINAR_PRODUCTO_ERROR = "ELIMINAR_PRODUCTO_ERROR";

export const CAMBIO_CANTIDAD_INICIO = "CAMBIO_CANTIDAD_INICIO";
export const CAMBIO_CANTIDAD_EXITO = "CAMBIO_CANTIDAD_EXITO";
export const CAMBIO_CANTIDAD_ERROR = "CAMBIO_CANTIDAD_ERROR";

export const CAMBIO_EMPAQUE_INICIO = "CAMBIO_EMPAQUE_INICIO";
export const CAMBIO_EMPAQUE_EXITO = "CAMBIO_EMPAQUE_EXITO";
export const CAMBIO_EMPAQUE_ERROR = "CAMBIO_EMPAQUE_ERROR";

export const ENVIAR_PRODUCTO_INICIO = "ENVIAR_PRODUCTO_INICIO";
export const ENVIAR_PRODUCTO_EXITO = "ENVIAR_PRODUCTO_EXITO";
export const ENVIAR_PRODUCTO_ERROR = "ENVIAR_PRODUCTO_ERROR";
export const BOTON_DIALOG_ENVIAR = "BOTON_DIALOG_ENVIAR";
export const BOTON_DIALOG_ENVIAR_PRINCIPAl = "BOTON_DIALOG_ENVIAR_PRINCIPAl";
export const VALIDANDO_fORMULARIO = "VALIDANDO_fORMULARIO";

export const VACIAR_CARRITO_INICIO = "VACIAR_CARRITO_INICIO";
export const VACIAR_CARRITO_EXITO = "VACIAR_CARRITO_EXITO";
export const VACIAR_CARRITO_ERROR = "VACIAR_CARRITO_ERROR";

export const VER_CARRITO = "VER_CARRITO";

export const AGREGAR_ITEM_VARIOS_INICIO = "AGREGAR_ITEM_VARIOS_INICIO";
export const AGREGADO_ITEM_VARIOS_EXITO = "AGREGADO_ITEM_VARIOS_EXITO";
export const ENVIAR_ITEM_VARIOS_EXITO = "ENVIAR_ITEM_VARIOS_EXITO";

export const SUSCRIBIRSE_INICIO = "SUSCRIBIRSE_INICIO";
export const SUSCRIBIRSE_EXITO = "SUSCRIBIRSE_EXITO";
export const SUSCRIBIRSE_ERROR = "SUSCRIBIRSE_ERROR";

export const IMAGEN_LOGO_INICIO = "IMAGEN_LOGO_INICIO";
export const IMAGEN_LOGO_EXITO = "IMAGEN_LOGO_EXITO";
export const IMAGEN_LOGO_ERROR = "IMAGEN_LOGO_ERROR";
export const SUSCRIBIRSE_NUEVAMENTE = "SUSCRIBIRSE_NUEVAMENTE";

export const LAYOUT_MOBIL = "LAYOUT_MOBIL";
export const GUARDAR_PRODUCTO_SEARCH = "GUARDAR_PRODUCTO_SEARCH";

export const INICIO_BANNER = "INICIO_BANNER";
export const EXITO_BANNER = "EXITO_BANNER";
export const ERROR_BANNER = "ERROR_BANNER";
export const EXITO_BANNER_ANUNCIOS = "EXITO_BANNER_ANUNCIOS";

export const EXITO_LANDINGPAGE = "EXITO_LANDINGPAGE";
export const ERROR_LANDINGPAGE = "ERROR_LANDINGPAGE";

export const INICIO_FORM_TIENDA_MEGAX = "INICIO_FORM_TIENDA_MEGAX";
export const EXITO_FORM_TIENDA_MEGAX = "EXITO_FORM_TIENDA_MEGAX";
export const ERROR_FORM_TIENDA_MEGAX = "ERROR_FORM_TIENDA_MEGAX";
export const ARCHIVO_CARGADO_EXITOSAMENTE = "IMAGEN_CARGADO_EXITOSAMENTE";
export const LEVANTAR_MODAL = "LEVANTAR_MODAL";

export const INICIO_GET_PRODUCT = "INICIO_GET_PRODUCT";
export const EXITO_GET_PRODUCT = "EXITO_GET_PRODUCT";
export const ERROR_GET_PRODUCT = "ERROR_GET_PRODUCT";


export const BOTON_LEVANTAR_PARA_ASOCIAR = "BOTON_LEVANTAR_PARA_ASOCIAR";
export const BOTON_LEVANTAR_PARA_ASOCIAR_BACKDROP= "BOTON_LEVANTAR_PARA_ASOCIAR_BACKDROP";

export const INICIO_PRODUCTOS_PORTADA= "INICIO_PRODUCTOS_PORTADA";
export const EXITO_PRODUCTOS_PORTADA = "EXITO_PRODUCTOS_PORTADA";
export const ERROR_PRODUCTOS_PORTADA = "ERROR_PRODUCTOS_PORTADA";

export const LEVANTAR_AUTORATING = "LEVANTAR_AUTORATING";

export const INICIO_FORM="INICIO_FORM";
export const EXITO_FORM="EXITO_FORM";
export const ERROR_FORM="ERROR_FORM";

export const GUARDAR_PRECIO="GUARDAR_PRECIO";
export const GUARDAR_TOTAL_SUBTOTAL="GUARDAR_TOTAL_SUBTOTAL";
export const GUARDAR_METODO_ENVIO="GUARDAR_METODO_ENVIO";
export const GUARDAR_METODO_ENVIO_COPIA="GUARDAR_METODO_ENVIO_COPIA";
export const GUARDAR_METODO_ENVIO_TOTALES="GUARDAR_METODO_ENVIO_TOTALES";
export const GUARDAR_MUNICIPIO="GUARDAR_MUNICIPIO";
export const GUARDAR_DM="GUARDAR_DM";
export const GUARDAR_METODO_ENVIO_MENOR="GUARDAR_METODO_ENVIO_MENOR"

export const GUARDAR_ENCABEZADO="GUARDAR_ENCABEZADO";

export const GUARDAR_NOMBRECATEGORIA_SELECCIONADO = "GUARDAR_NOMBRECATEGORIA_SELECCIONADO"